import React, { useState } from "react";
import { NamedValue } from "../utils/voteUtils";
import { CircularProgressbar } from "react-circular-progressbar";
import Title from "antd/es/typography/Title";
import { Button, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const SeatsHalfDonut = ({ seats }: { seats?: NamedValue[] }) => {
  const _seats = seats ?? [];
  const filteredSeats = _seats.filter((s) => s.value > 0);

  const [selectedSeats, setSelectedSeats] = useState<NamedValue[]>([]);

  const currentSelectedSeatValues = selectedSeats.reduce(
    (p, c) => p + c.value,
    0
  );

  const hasCoalition = currentSelectedSeatValues > 75;

  function onChange(e: CheckboxChangeEvent) {
    const name = e.target.value;
    if (selectedSeats.find((x) => x.name === name)) {
      setSelectedSeats((seats) => seats.filter((x) => x.name !== name));
    } else {
      const nv: NamedValue | undefined = filteredSeats.find(
        (x) => x.name === name
      );
      if (nv) setSelectedSeats((seats) => [...seats, nv]);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ height: 350 }}>
        <div
          style={{
            position: "absolute",
            top: "5vh",
            left: "5rem",
            height: "20vh",
            width: "30vw",
          }}
        >
          <CircularProgressbar
            value={100}
            circleRatio={0.5}
            styles={{
              root: {
                transform: "rotate(0.75turn)",
              },
              path: { stroke: "gray" },
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "5vh",
            left: "5rem",
            height: "20vh",
            width: "30vw",
          }}
        >
          <CircularProgressbar
            value={currentSelectedSeatValues / 1.5}
            circleRatio={0.5}
            styles={{
              root: {
                transform: "rotate(0.75turn)",
              },
              path: { stroke: hasCoalition ? "limegreen" : "coral" },
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "9rem",
            right: "-0.5rem",
            height: "20vh",
            width: "30vw",
          }}
        >
          <div
            style={{
              width: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Title level={3} style={{ marginBottom: -12 }}>
              {hasCoalition ? "Coalitie!" : "Geen coalitie..."}
            </Title>
            <Title level={5}>{currentSelectedSeatValues} / 150</Title>
            {currentSelectedSeatValues > 0 && (
              <Button
                style={{ marginTop: 60 }}
                onClick={() => setSelectedSeats([])}
              >
                Wis selectie
              </Button>
            )}
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "0px 24px",
          }}
        >
          {filteredSeats.map((seat) => {
            return (
              <Checkbox
                className='normal-checkbox'
                value={seat.name}
                onChange={onChange}
                checked={
                  selectedSeats.find((x) => x.name === seat.name) != undefined
                }
              >
                {seat.name}: <b>{seat.value}</b>
              </Checkbox>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SeatsHalfDonut;
