import React from "react";
import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";

const EmptyScreen = () => {
  return (
    <Row style={{ height: "50%", width: "100%" }}>
      <Col span={6} />
      <Col span={12}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Title level={2} style={{ marginTop: 200 }}>
            Er zijn nog geen gemeenten geselecteerd.
          </Title>
        </div>
      </Col>
      <Col span={6} />
    </Row>
  );
};

export default EmptyScreen;
