import { create } from "zustand";
import votes from "../data/votes.json";

export type Municipality = {
  name: string;
  votes: number[];
};

interface VoteState {
  allMunicipalities: Municipality[];
  selectedMunicipalities: Municipality[];
  addMunicipality: (m: Municipality) => void;
  removeMunicipality: (s: string) => void;
  clearSelectedMunicipalities: () => void;
  selectAllMunicipalities: () => void;
}

function getDataAsMunicipalities(): Municipality[] {
  let municipalities: Municipality[] = [];
  votes.map((x) => {
    municipalities.push({ name: x.Name, votes: x.Votes });
  });
  return municipalities;
}

const useVoteStore = create<VoteState>()((set) => ({
  allMunicipalities: getDataAsMunicipalities(),
  selectedMunicipalities: [],
  addMunicipality: (m) =>
    set((state) => ({
      selectedMunicipalities: [...state.selectedMunicipalities, m],
    })),
  removeMunicipality: (s) =>
    set((state) => ({
      selectedMunicipalities: state.selectedMunicipalities.filter(
        (x) => x.name !== s
      ),
    })),
  clearSelectedMunicipalities: () => set({ selectedMunicipalities: [] }),
  selectAllMunicipalities: () =>
    set((state) => ({ selectedMunicipalities: state.allMunicipalities })),
}));

export default useVoteStore;
