import { Layout, Row, Col } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header, Footer } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React from "react";
import MunicipalityPicker from "../components/MunicipalityPicker";
import useVoteStore from "../store/useVoteStore";
import ElectionScreen from "../components/ElectionScreen";
import EmptyScreen from "./EmptyScreen";

const ElectionsAnalyzer = () => {
  const selectedMunicipalities = useVoteStore(
    (state) => state.selectedMunicipalities
  );
  return (
    <Layout style={{ width: "100%", height: "100vh", minHeight: "100%" }}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: 16 }}>
          <Title level={2} style={{ color: "white" }}>
            Tweede Kamer verkiezingsanalyse 2023
          </Title>
        </div>
      </Header>
      <Layout>
        <Sider width={250}>
          <Row style={{ height: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Title level={4} style={{ color: "white" }}>
                  Gemeenten
                </Title>
                <MunicipalityPicker />
              </div>

              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  justifySelf: "end",
                }}
              >
                Daan van Dongen © 2023
              </p>
            </div>
          </Row>
        </Sider>
        <Content style={{ padding: 24 }}>
          <Row>
            <Col span={24}>
              {selectedMunicipalities.length > 0 ? (
                <ElectionScreen />
              ) : (
                <EmptyScreen />
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ElectionsAnalyzer;
