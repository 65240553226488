import React from "react";
import useVoteStore, { Municipality } from "../store/useVoteStore";
import { Button, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const MunicipalityPicker = () => {
  const allMunicipalities = useVoteStore((state) => state.allMunicipalities);
  const selectedMunicipalities = useVoteStore(
    (state) => state.selectedMunicipalities
  );
  const addMunicipality = useVoteStore((state) => state.addMunicipality);
  const removeMunicipality = useVoteStore((state) => state.removeMunicipality);
  const clearAllSelectedMunicipalities = useVoteStore(
    (state) => state.clearSelectedMunicipalities
  );
  const selectAllMunicipalities = useVoteStore(
    (state) => state.selectAllMunicipalities
  );

  const onChange = (e: CheckboxChangeEvent) => {
    const name = e.target.value;
    if (selectedMunicipalities.find((x) => x.name === name)) {
      removeMunicipality(name);
    } else {
      const m: Municipality | undefined = allMunicipalities.find(
        (x) => x.name === name
      );
      if (m) addMunicipality(m);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "65vh",
          width: "80%",
          overflowY: "scroll",
          padding: "8px 16px",
        }}
        className='secondary-blue'
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {allMunicipalities.map((m) => {
            return (
              <Checkbox
                value={m.name}
                onChange={onChange}
                checked={
                  selectedMunicipalities.find((x) => x.name === m.name) !=
                  undefined
                }
              >
                {m.name}
              </Checkbox>
            );
          })}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          style={{ marginTop: 16, width: "40%", padding: 0 }}
          onClick={() => clearAllSelectedMunicipalities()}
        >
          Wis selectie
        </Button>
        {/* <Button
          style={{ marginTop: 16, width: "40%", padding: 0 }}
          onClick={() => selectAllMunicipalities()}
          disabled
        >
          Selecteer alles
        </Button> */}
      </div>
    </div>
  );
};

export default MunicipalityPicker;
