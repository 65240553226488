import { Municipality } from "../store/useVoteStore";

export type NamedValue = {
  name: string;
  value: number;
};

export const emptyVoteArray: number[] = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
];

function indexToParty(index: number): string {
  if (index < 1 || index > 26) {
    console.error(
      "Found illegal index when converting to party name: " + index
    );
    return "";
  }

  switch (index) {
    case 1:
      return "VVD";
    case 2:
      return "D66";
    case 3:
      return "GL/PvdA";
    case 4:
      return "PVV";
    case 5:
      return "CDA";
    case 6:
      return "SP";
    case 7:
      return "FvD";
    case 8:
      return "PvdD";
    case 9:
      return "CU";
    case 10:
      return "Volt";
    case 11:
      return "JA21";
    case 12:
      return "SGP";
    case 13:
      return "DENK";
    case 14:
      return "50PLUS";
    case 15:
      return "BBB";
    case 16:
      return "BIJ1";
    case 17:
      return "Piratenpartij - De Groenen";
    case 18:
      return "BVNL";
    case 19:
      return "NSC";
    case 20:
      return "Splinter";
    case 21:
      return "LP";
    case 22:
      return "LEF";
    case 23:
      return "Samen voor Nederland";
    case 24:
      return "Nederland met een PLAN";
    case 25:
      return "Partij van de Sport";
    case 26:
      return "Politieke Partij voor Basisinkomen";
  }

  return "";
}

export function voteArrayToPartySeats(votes: number[]): number[] {
  let _votes = [...votes];
  const totalVotes = votes.reduce((prev, curr) => prev + curr, 0);
  const electoralThreshold = Math.ceil(totalVotes / 150);

  let seats: number[] = [];

  // Assign full seats
  for (let i = 0; i < votes.length; i++) {
    const s = Math.floor(votes[i] / electoralThreshold);
    seats.push(s);
    _votes[i] -= s * electoralThreshold;
  }

  let filledSeats = seats.reduce((prev, curr) => prev + curr, 0);

  // Parties whose vote count did not meet the electoralThreshold can not get a seat at all.
  for (let i = 0; i < _votes.length; i++) {
    if (seats[i] === 0) {
      _votes[i] = 0;
    }
  }

  while (filledSeats < 150) {
    let highestIndex = 0;
    let highestVotesShare = 0;

    for (let i = 0; i < _votes.length; i++) {
      const vs = _votes[i] / (seats[i] + 1);
      if (vs > highestVotesShare) {
        highestIndex = i;
        highestVotesShare = vs;
      }
    }
    filledSeats++;
    seats[highestIndex]++;
    _votes[highestIndex] = 0;
  }

  return seats;
}

export function arrayToNamedArray(nums: number[]): NamedValue[] {
  const result: NamedValue[] = [];
  for (let i = 0; i < nums.length; i++) {
    result.push({
      name: indexToParty(i + 1),
      value: nums[i],
    });
  }

  return result;
}

export function sumArrays(...arrays: number[][]): number[] {
  console.log("Arrays", ...arrays);
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  const res = result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0)
  );
  console.log("result", res);
  return res;
}

export function sumMunicipalityVotes(muns: Municipality[]): number[] {
  const arrs = muns.map((x) => x.votes);
  return sumArrays(...arrs);
}

export function getTotalVotes(votes: number[]): number {
  return votes.reduce((p, c) => p + c, 0);
}
