import React, { useState, useEffect } from "react";
import useVoteStore from "../store/useVoteStore";
import {
  arrayToNamedArray,
  getTotalVotes,
  sumArrays,
  sumMunicipalityVotes,
  voteArrayToPartySeats,
  NamedValue,
  emptyVoteArray,
} from "../utils/voteUtils";
import { Col, Row, Statistic } from "antd";
import Card from "./Card";
import SeatsHalfDonut from "../graphs/SeatsHalfDonut";
import SeatsColumn from "../graphs/SeatsColumn";

const ElectionScreen = () => {
  const selectedMunicipalities = useVoteStore(
    (state) => state.selectedMunicipalities
  );

  const [votes, setVotes] = useState<number[]>(emptyVoteArray);
  const [seats, setSeats] = useState<number[]>(emptyVoteArray);
  const [totalVotes, setTotalVotes] = useState<number>(0);

  useEffect(() => {
    if (selectedMunicipalities.length === 0) {
      setVotes(emptyVoteArray);
      setSeats(emptyVoteArray);
      setTotalVotes(0);
      return;
    }
    const v = sumMunicipalityVotes(selectedMunicipalities);
    console.log(v);
    setVotes(v);

    const s = voteArrayToPartySeats(v);
    console.log(s);
    setSeats(s);

    console.log("DB votes", v);
    const t = getTotalVotes(v);
    console.log(t);
    setTotalVotes(t);

    console.log("Municipality update");
  }, [selectedMunicipalities]);

  console.log(seats);
  return (
    <Row style={{ height: "50%", width: "100%" }} gutter={[24, 24]}>
      <Col span={12}>
        <Card>
          <SeatsColumn seats={arrayToNamedArray(seats)} />
        </Card>
        <Row style={{ height: 24 }} />
        <Row>
          <Col span={8}>
            <Card>
              <Row>
                <Col>
                  <Statistic
                    title='Aantal gemeenten'
                    value={selectedMunicipalities.length}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <Card>
              <Row>
                <Col>
                  <Statistic title='Aantal stemmen' value={totalVotes} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <Card>
              <Row>
                <Col>
                  <Statistic
                    title='Stemmen / zetel'
                    value={Math.ceil(totalVotes / 150)}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <Card>
          <SeatsHalfDonut seats={arrayToNamedArray(seats)} />
        </Card>
      </Col>
    </Row>
  );
};

export default ElectionScreen;
