import React from "react";
import { NamedValue } from "../utils/voteUtils";
import { Column, Pie } from "@ant-design/charts";
import Title from "antd/es/typography/Title";

const SeatsColumn = ({ seats }: { seats?: NamedValue[] }) => {
  const _seats = seats ?? [];
  const filteredSeats = _seats.filter((s) => s.value > 0);
  const data = filteredSeats;

  const config = {
    data,
    yField: "value",
    xField: "name",
    height: 300,
    maxHeight: 300,
  };

  console.log(config);

  return (
    <div style={{ height: 350, display: "flex", flexDirection: "column" }}>
      <Title level={3} style={{ marginTop: 0, marginBottom: 24 }}>
        Zetelverdeling
      </Title>
      <div style={{ height: 320, marginLeft: -24, marginBottom: -36 }}>
        <Column {...config} />
      </div>
    </div>
  );
};

export default SeatsColumn;
